<template>
  <b-overlay
    :show="isLoading"
    rounded="lg"
    opacity="0.6"
    spinner-variant="primary"
  >
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row class="mt-4">
      <b-col cols="12">
        <router-link class="btn btn-sm btn-default" :to="{name: PublisherManagePath.name}">
					<em class="fa fa-backward"></em> Manage Publisher
				</router-link>
      </b-col>
      <b-col v-if="item">
        <b-form @submit="onSubmit">
          <div class="card mt-4">
            <div class="bg-success p-3">
              <h5 class="card-title mb-0 font-weight-bold">
                Edit Publisher ({{ this.form.email }})
              </h5>
            </div>
            <div class="card-body">
              <b-row>
                <b-col lg="6" class="mt-4">
                  <b-form-group label="Email *">
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      type="email"
                      step="any"
                      placeholder="email@domain.com"
                    >
                    </b-form-input>
                    <b-form-group class="mt-3" label="Username">
                    <b-form-input
                      id="username"
                      v-model="form.username"
                      type="text"
                      step="any"
                      placeholder="input first name"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Password">
                    <div class="input-group">
                  <input
                    v-if="showPassword"
                    type="text"
                    class="form-control"
                    id="password"
                    placeholder="type password.."
                    v-model="form.password"
                    
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="type password.."
                    v-model="form.password"
                    
                  />
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroupPrepend2"
                      @click="toggleShow"
                    >
                      <i
                        class="fa"
                        :class="{
                          'fa-eye-slash': !showPassword,
                          'fa-eye': showPassword,
                        }"
                      ></i>
                    </span>
                  </div>
                </div>
                <p class="mt-2 mb-0">field di isi jika password ingin di ubah</p>
                  </b-form-group>
                  <b-form-group label="Organization *">
                    <multiselect 
                      v-model="form.organization" 
                      label="text" 
                      track-by="value" 
                      placeholder="type organization name" 
                      open-direction="top" 
                      :options="organizationSearchOptions" 
                      :searchable="true" 
                      :loading="isOrganizationSearch" 
                      :close-on-select="true" 
                      :clear-on-select="false" 
                      :preserve-search="false" 
                      :preselect-first="false" 
                      :multiple="false" 
                      :taggable="false" 
                      @search-change="organizationFind" 
                      @input="organizationChange" 
                      required>
                    </multiselect>
                  </b-form-group>
                  <b-form-group label="Publisher *">
                    <multiselect 
                    v-model="form.vendor" 
                    label="name" 
                    open-direction="top" 
                    track-by="id" 
                    placeholder="Publisher" 
                    :options="publisherSearchOptions" 
                    :searchable="false" 
                    :loading="isPublisherSearch" 
                    :close-on-select="true"  
                    :clear-on-select="false" 
                    :preserve-search="false" 
                    :preselect-first="false" 
                    :multiple="false" 
                    :taggable="false" 
                    :select-label-on-tab="false" 
                    required>
                </multiselect>
									</b-form-group>
                  </b-form-group>
                </b-col>  
              </b-row>
              <b-row>
                <b-col class="mt-4">
                  <b-button variant="primary" type="submit">Update</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { PublisherManagePath, PublisherUpdatePath } from '../../router/account';
export default {
  name: 'updatePublisher',
  data() {
    return {
      PublisherUpdatePath,
      PublisherManagePath,
      selectedOrganization: null,
      isOrganizationSearch: false,
      foundIndexPubliher:false,
      showPassword:false,
      value: null,
      debounce: null,
      form: {
        id: null,
        email: null,
        username: null,
        password:null,
        organization: null,
        vendor: null
      },
      organizationSearchOptions: [],
      publisherSearchOptions: [],
      isPublisherSearch: false,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.accessPublisher.isError,
      isLoading: (state) => state.accessPublisher.isLoading,
      errorMessage: (state) => state.accessPublisher.errorMessage,
      successMessage: (state) => state.accessPublisher.successMessage,
      item: (state) => state.accessPublisher.item,
    }),
  },
  watch: {
    errorMessage() {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage() {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      Object.assign(this.$data, this.$options.data.call(this));
      this.$router.push(PublisherManagePath); 
    },
    item() {
      if (!this.item) {
        this.messageAlert('error', 'not found');
        return;
      }
      if (!Object.keys(this.item).length) return;
      this.setEditPublisher();
    },
  },
  created() {
    this.fetchPublisherById(this.$route.params.id);
    this.setEditPublisher();  
  },
  
  methods: {
    ...mapActions('accessPublisher', ['updatePublisherById', 'fetchPublisherById']),
    ...mapActions("organization", {
      AutocompleteSearch: "AutocompleteSearch"
    }), 
    ...mapActions("vendors", ["AutocompleteVendorByOrganizationId"]),
    onSubmit(event) {
      event.preventDefault();
      const data = this.form;

      const payload = {
        id: data.id,
        field : {
        username:data.username,
        email:data.email,
        id_organization:data?.organization?.value,
        id_vendor:data?.vendor?.id,
        password: data?.password,
        },
      };
      this.updatePublisherById(payload);    
    },
    setEditPublisher() {
      const data = this.item;
      this.form.id = data?.id;
      this.form.email = data?.email;
      this.form.organization = { value: data?.organization?.id, text: data?.organization?.name };
      this.form.vendor = { value: data?.vendor?.id, text: data?.vendor?.name };
      this.publisherFind();
      this.form.username = data?.username;
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    organizationFind(query) {
      if (!query) return;

      this.isOrganizationSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.AutocompleteSearch({ q: query })
          .then((response) => {
            this.organizationSearchOptions = response.data.data.rows;
            this.isOrganizationSearch = false;
          })
          .catch(() => {
            this.isOrganizationSearch = false;
            this.form.organization = null;
          });
      }, 1200);
    },
    organizationChange() {
     this.publisherFind();
    },
    publisherFind() {
      this.isPublisherSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        const idOrganization = this.form.organization.value;
        this.AutocompleteVendorByOrganizationId({ id: idOrganization })
          .then((response) => {
            this.publisherSearchOptions = response.data.data.rows;
            this.foundIndexPubliher = false
              this.publisherSearchOptions.some(option => {  
                  if (parseInt(this.item.vendor.id) === option.id) {
                      this.form.vendor = option;       
                      this.foundIndexPubliher = true;
                      return true;
                  }
                return false;
            });
        
        if (!this.foundIndexPubliher && this.publisherSearchOptions.length > 0) {
              this.form.vendor = this.publisherSearchOptions[0];
          }

    this.isPublisherSearch = false;
          })
          .catch(() => {
            this.isPublisherSearch = false;
            this.form.vendor = null; 
          });
      }, 1200);
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    formatDate(tgl) {
      if (tgl) {
        return moment(tgl).format('YYYY-MM-DD');
      }
      return '-';
    },
  },
};
</script>
